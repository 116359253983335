// "Production" enabled environment
export const environment = {
    production: true,
    hmr: false,
    api_url: "https://azapp-vsaude-legacy-api-hml.azurewebsites.net",
    instrumentationKey: "54a15246-de3e-4fac-9cb9-83ffc1f3ed1f",
    vforms_url: "https://kind-grass-08489601e.3.azurestaticapps.net",
    vadmin_url: "https://jolly-ground-08011be1e.2.azurestaticapps.net/v2",
    supportChatEnabled: true,
    wootricAccountToken: "$(wootricAccountToken)",
    gtmId: "GTM-K4LHFP5",
    heatmapKey: "$(heatmapKey)",
    z_url: 'https://api.zoop.ws',
    z_marketplace: '468fc07005f745f2a1fb6b61e1bf64e0',
    z_key: 'zpk_test_GrQxKKmJEpgAMRuPz7kOOL5O',
    blob_url: 'https://stvsaudehml001.blob.core.windows.net',
    statistics_api_url: "$(statistics_api_url)",
};